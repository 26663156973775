<template>
    <v-container>
        <AdBTWR v-if="!user.subscribed"/>

        <v-row justify="center" v-if="user.authenticated" class="mt-5">
            <v-col cols="12" sm="10" md="6" lg="5" xl="4">
                <v-form ref="form" v-model="valid">
                    <v-card class="elevation-5">
                        <v-card-title class="headline font-weight-light">Change Password</v-card-title>
                        <v-card-text>
                            <p>Enter your current password followed by your new password below.</p>

                            <input type="hidden" name="username" :value="user.username">

                            <v-text-field
                                v-model="passwordCurrent"
                                type="password"
                                label="Current Password"
                                :rules="passwordCurrentRules"
                                autocomplete="current-password"
                                outlined
                            ></v-text-field>

                            <v-text-field
                                v-model="passwordNew"
                                type="password"
                                label="New Password"
                                :rules="passwordNewRules"
                                :counter="128"
                                autocomplete="new-password"
                                outlined
                            ></v-text-field>

                            <v-text-field
                                v-model="passwordConfirm"
                                type="password"
                                label="Confirm Password"
                                :rules="passwordNewRules.concat(passwordConfirmationRule)"
                                :counter="128"
                                autocomplete="new-password"
                                outlined
                            ></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="rfaccent2" :dark="valid" :disabled="!valid" :loading="loading"
                                   @click="changePassword">Change
                                Password
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>

            <v-snackbar
                v-model="snackbar.state"
                :color="snackbar.color"
                :timeout="snackbar.timeout"
                multi-line
                top
            >
                <v-icon v-if="snackbar.icon" class="mr-1">{{ snackbar.icon }}</v-icon>
                {{ snackbar.text }}
            </v-snackbar>

        </v-row>
        <v-row v-else>
            <v-col cols="12">
                <v-alert type="warning" class="mt-5" border="left" elevation="3" prominent>
                    You must be logged in to change your password.
                </v-alert>

                <Login v-on="$listeners"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import config from '../../config';
    import axios from 'axios';
    import Login from '@/components/Login.vue';
    import AdBTWR from '@/components/ads/BTWR.vue';
    import {useTitle} from '@vueuse/core';

    export default {
        name: 'ChangePassword',

        props: ['user'],

        components: {Login, AdBTWR},

        data: () => ({
            valid: false,
            loading: false,
            passwordCurrent: null,
            passwordNew: null,
            passwordConfirm: null,
            passwordCurrentRules: [
                v => !!v || 'Password is required',
            ],
            passwordNewRules: [
                v => !!v || 'New Password is required',
                v => (v && v.length >= 8) || 'Must be at least 8 characters',
                v => (v && v.length <= 128) || 'Must be no greater than 128 characters',
            ],
            //@todo need to make sure new password and confirm new password both match!!
            snackbar: {
                state: false,
                color: null,
                timeout: 1000,
                text: null
            }
        }),

        computed: {
            passwordConfirmationRule() {
                return () => (this.passwordNew === this.passwordConfirm) || 'Password must match'
            }
        },

        methods: {
            changePassword() {
                this.loading = true;

                let axiosHeaders = {};
                if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios.post(config.API_LOCATION + '/password', {
                    currentPassword: this.passwordCurrent,
                    newPassword: this.passwordNew
                }, {
                    headers: axiosHeaders
                })
                    .then(response => {
                        if (response.data.success) {
                            this.snackbar.state = false;
                            this.snackbar.color = 'success';
                            this.snackbar.icon = 'mdi-check';
                            this.snackbar.text = 'Your password has been updated successfully.';
                            this.snackbar.state = true;
                        }
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 401) {
                            this.$emit('unauthorized');
                        } else if (err.response && err.response.status === 403) {
                            this.snackbar.state = false;
                            this.snackbar.color = 'error';
                            this.snackbar.icon = 'mdi-alert';
                            this.snackbar.text = 'The current password you entered is invalid.';
                            this.snackbar.state = true;
                        } else if (err.response && err.response.status === 400) {
                            //@todo validation error from server
                            // this.snackbar.state = false;
                            // this.snackbar.color = 'error';
                            // this.snackbar.icon = 'mdi-alert';
                            // this.snackbar.text = 'Your current password is invalid.';
                            // this.snackbar.state = true;
                        }
                    })
                    .then(() => {
                        this.loading = false;
                    });
            }
        },

        mounted() {
            useTitle('Change Password - myGMRS.com');
        }
    }

</script>
